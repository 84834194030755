import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  clearError(e) {
    // this.clearErrors();
    // Elimina el mensaje de error si existe
    const inputs = this.element.querySelectorAll("input.obligatorio-recurso, select.obligatorio-recurso");
    // console.log('inputs',inputs)
    const filledInputs = Array.from(inputs).filter((input) => input?.value?.trim() !== "");
    
    if (filledInputs.length > 0) {
      // console.log('filledInputs',filledInputs)
      // Si al menos un campo está completo, verifica que todos estén llenos
      inputs.forEach((input) => {
        if (input.value.trim() !== "") {
          if (input.tomselect) {
             this.error = e.target.parentElement.querySelector(".error-message");
          } else {
            this.error = e.target.nextElementSibling;
          }
          console.log('error',this.error)
          if (this.error && this.error.classList.contains("error-message")) {
            this.error.remove();
            e.target.classList.remove("is-invalid");

          }
        } 
      });
    }
  }
  showError(input, message) {
    const error = document.createElement("div");
    error.className = "error-message text-danger mt-1";
    error.innerText = message;

    // Agrega el mensaje de error después del input
    input.parentElement.appendChild(error);
    input.classList.add("is-invalid");
  }

  clearErrors() {
    this.element.querySelectorAll(".error-message").forEach((error) => error.remove());
    this.element.querySelectorAll(".is-invalid").forEach((input) => input.classList.remove("is-invalid"));
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  addDocumentoInternacion(event) {
    const nuevosAdjuntos = document.getElementById("nuevos-adjuntos");
    let index = nuevosAdjuntos.childElementCount + 1;
    const fileFormats = JSON.stringify(['png', 'jpg', 'jpeg', 'pdf']).replace(/"/g, '&quot;');

    nuevosAdjuntos.insertAdjacentHTML(
      "beforeend",
      `
        <div class="row mt-3 border-top pt-2 adjunto" id="adjunto_${index}" data-modulo-validator-target="group">
          <div class="insertar-botoncerrar d-flex justify-content-end">
            <i  style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer; color:  #004A97;"
                class="boton-cerrar fa-regular fa-circle-xmark"
                data-action="click->documentos-adjuntos#removeDocumentoInternacion"
                data-index="${index}"></i>
          </div>
          <div class="row">
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Tipo de documento</div>
              <select label="false" class="icon-select form-control input-paciente-datos obligatorio-recurso"
                      name="internacion[adjuntos][adjunto_${index}][tipo_documento]"
                      id="internacion_adjuntos_adjunto_${index}_tipo_documento"
                      data-action="change->input-empty-validator#clearError">
                <option value="">Selecciona tipo de documento</option>
                <option value="Documento del paciente">Documento del paciente</option>
                <option value="Documento de la internación">Documento de la internación</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Fecha</div>
              <div class="mb-0 string optional internacion_adjuntos_adjunto_${index}_fecha">
                <input  class="form-control string optional input-paciente-datos obligatorio-recurso"
                        type="date"
                        onkeydown="return false;" max="${new Date().toISOString().split('T')[0]}"
                        name="internacion[adjuntos][adjunto_${index}][fecha]"
                        id="internacion_adjuntos_adjunto_${index}_fecha"
                        data-action="change->input-empty-validator#clearError">
              </div>
            </div>
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Descripción</div>
              <input  label="false" placeholder="Indica descripción"
                      class="form-control input-paciente-datos obligatorio-recurso"
                      maxlength="100" size="100" type="text"
                      name="internacion[adjuntos][adjunto_${index}][descripcion_adjuntos]"
                      id="internacion_adjuntos_adjunto_${index}_descripcion_adjuntos"
                      data-action="change->input-empty-validator#clearError">
            </div>
          </div>
          <div class="row" data-controller="file-format" data-file-format-array-value="${fileFormats}">
            <div class="col-6 input-container-form" data-action="click->file-format#openFileDialog">
              <div class="input-file" data-action="click->file-format#openFileDialog">
                <label  class="mb-0 label-paciente-datos"
                        for="internacion_adjuntos_adjunto_${index}_file">Archivo adjunto</label>
                <div data-file-format-target="inputFile" data-action="click->file-format#hola">
                  <div class="mb-3 file optional internacion_adjuntos_adjunto_${index}_file">
                    <input  class="form-control file optional obligatorio-recurso"
                            id="internacion_adjuntos_adjunto_${index}_file"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            data-action="change->file-format#displayFileName change->input-empty-validator#clearError"
                            type="file"
                            name="internacion[adjuntos][adjunto_${index}][file]">
                  </div>
                </div>
              </div>
              <span id="error_message" class="error-message text-danger"></span>
            </div>
          </div>
        </div>
      `
    );
  }

  removeDocumentoInternacion(event) {
    const index = event.target.dataset.index;
    const adjunto = document.getElementById(`adjunto_${index}`);
    adjunto.remove();

    // Actualizar los index de los elementos adjuntos
    const adjuntos = document.querySelectorAll('.adjunto');
    adjuntos.forEach((adjunto, i) => {
      const inputName = adjunto.querySelector('input').name;
      const inputId = adjunto.querySelector('input').id;
      const label = adjunto.querySelector('label');
      const id_adjunto = adjunto.id;
      adjunto.querySelector('input').name = inputName.replace(/\d+/, i + 1);
      adjunto.querySelector('input').id = inputId.replace(/\d+/, i + 1);
      label.htmlFor = inputId.replace(/\d+/, i + 1);
      adjunto.querySelector('i').dataset.index = i + 1;
      adjunto.id = id_adjunto.replace(/\d+/, i + 1);
    });
  }

  // addServicioDocumento(event) {
  //   const ajuntos_servicio_box = document.getElementById("adjuntos-servicio-box");
  //   let index = ajuntos_servicio_box.childElementCount;
  //   const fileFormats = JSON.stringify(['png', 'jpg', 'jpeg', 'pdf']).replace(/"/g, '&quot;');

  //   ajuntos_servicio_box.insertAdjacentHTML(
  //     "beforeend",
  //     `
  //       <div class="row mt-3 border-top pt-4 adjunto" id="adjunto_${index}">
  //         <div class="insertar-botoncerrar d-flex justify-content-end">
  //           <i  style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer; color:  #004A97;"
  //               class="boton-cerrar fa-regular fa-circle-xmark"
  //               data-action="click->documentos-adjuntos#removeDocumentoInternacion"
  //               data-index="${index}"></i>
  //         </div>
  //         <div class="row">
  //           <div class="col-6 input-container-form">
  //             <div class="label-paciente-datos">Tipo de documento</div>
  //             <select label="false" class="icon-select form-control input-paciente-datos"
  //                     name="internacion[servicios][servicio_${index}][adjuntos_servicios][][tipo]"
  //                     id="internacion_adjuntos_adjunto_${index}_tipo_documento">
  //               <option value="">Selecciona tipo de documento</option>
  //               <option value="documento" selected>Documentos del Servicio</option>
  //             </select>
  //           </div>
  //         </div>
  //         <div class="row">
  //           <div class="col-6 input-container-form">
  //             <div class="label-paciente-datos">Fecha</div>
  //             <div class="mb-3 string optional internacion_adjuntos_adjunto_${index}_fecha">
  //               <input  class="form-control string optional input-paciente-datos" type="date"
  //                       onkeydown="return false;" max="${new Date().toISOString().split('T')[0]}"
  //                       name="internacion[servicios][servicio_${index}][adjuntos_servicios][][fecha]"
  //                       id="internacion_adjuntos_adjunto_${index}_fecha">
  //             </div>
  //           </div>
  //           <div class="col-6 input-container-form">
  //             <div class="label-paciente-datos">Descripción</div>
  //             <input  label="false" placeholder="Indica descripción"
  //                     class="form-control input-paciente-datos" maxlength="100" size="100" type="text"
  //                     name="internacion[servicios][servicio_${index}][adjuntos_servicios][][descripcion]"
  //                     id="internacion_adjuntos_adjunto_${index}_descripcion">
  //           </div>
  //         </div>
  //         <div class="row" data-controller="file-format" data-file-format-array-value="${fileFormats}">
  //           <div class="col-6 input-container-form" data-action="click->file-format#openFileDialog">
  //             <div class="input-file" data-action="click->file-format#openFileDialog">
  //               <label class="mb-0 label-paciente-datos" for="internacion_adjuntos_adjunto_${index}_file">Diagnóstico adjunto (opcional)</label>
  //               <div data-file-format-target="inputFile" data-action="click->file-format#hola">
  //                 <div class="mb-3 file optional internacion_adjuntos_adjunto_${index}_file">
  //                   <input  class="form-control file optional"
  //                           id="internacion_adjuntos_adjunto_${index}_file"
  //                           accept="image/png, image/jpeg, image/jpg, application/pdf"
  //                           data-action="change->file-format#displayFileName"
  //                           type="file"
  //                           name="internacion[servicios][servicio_${index}][adjuntos_servicios][][url_archivo]">
  //                 </div>
  //               </div>
  //             </div>
  //             <span id="error_message" class="error-message text-danger"></span>
  //           </div>
  //         </div>
  //       </div>
  //     `
  //   );
  // }

  addServicioDocumento(event) {
    const ajuntosServicioBox = document.getElementById("adjuntos-servicio-box");
  
    // Calcula el índice basándose en los elementos existentes con la clase "adjunto"
    let index = ajuntosServicioBox.querySelectorAll(".adjunto").length;
  
    const fileFormats = JSON.stringify(['png', 'jpg', 'jpeg', 'pdf']).replace(/"/g, '&quot;');
  
    ajuntosServicioBox.insertAdjacentHTML(
      "beforeend",
      `
        <div class="row mt-3 border-top pt-4 adjunto" id="adjunto_${index}">
          <div class="insertar-botoncerrar d-flex justify-content-end">
            <i style="font-size: 1.5rem; color: #004A97; cursor: pointer;"
               class="boton-cerrar fa-regular fa-circle-xmark"
               data-action="click->documentos-adjuntos#removeDocumentoInternacion"
               data-index="${index}"></i>
          </div>
          <div class="row">
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Tipo de documento</div>
              <select label="false" class="icon-select form-control input-paciente-datos"
                      name="internacion[servicios][servicio_1][adjuntos_servicios][][tipo]"
                      id="internacion_adjuntos_adjunto_${index}_tipo_documento">
                <option value="">Selecciona tipo de documento</option>
                <option value="documento" selected>Documentos del Servicio</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Fecha</div>
              <div class="mb-3 string optional internacion_adjuntos_adjunto_${index}_fecha">
                <input class="form-control string optional input-paciente-datos" type="date"
                       onkeydown="return false;" max="${new Date().toISOString().split('T')[0]}"
                       name="internacion[servicios][servicio_1][adjuntos_servicios][][fecha]"
                       id="internacion_adjuntos_adjunto_${index}_fecha">
              </div>
            </div>
            <div class="col-6 input-container-form">
              <div class="label-paciente-datos">Descripción</div>
              <input label="false" placeholder="Indica descripción"
                     class="form-control input-paciente-datos" maxlength="100" size="100" type="text"
                     name="internacion[servicios][servicio_1][adjuntos_servicios][][descripcion]"
                     id="internacion_adjuntos_adjunto_${index}_descripcion">
            </div>
          </div>
          <div class="row" data-controller="file-format" data-file-format-array-value="${fileFormats}">
            <div class="col-6 input-container-form">
              <label class="mb-0 label-paciente-datos" for="internacion_adjuntos_adjunto_${index}_file">Archivo adjunto (opcional)</label>
              <input class="form-control file optional" id="internacion_adjuntos_adjunto_${index}_file"
                     accept="image/png, image/jpeg, image/jpg, application/pdf"
                     type="file"
                     name="internacion[servicios][servicio_1][adjuntos_servicios][][url_archivo]">
            </div>
          </div>
        </div>
      `
    );
  }
  
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "message"]

  connect() {
  }

  // validate() {
  //   const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
  //   const email = this.emailTarget
  //   const message = this.messageTarget

  //   if (email.value === '') {
  //     message.style.display = "none"
  //     message.innerText = ""
  //   } else if (emailRegex.test(email.value)) {
  //     message.style.display = "none"
  //     message.innerText = ""
  //   } else {
  //     email.value = ''
  //     message.style.display = "block"
  //     message.innerText = "El formato de correo no es válido"
  //   }
  // }

  validate() {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    const email = this.emailTarget;
    const message = this.messageTarget;
  
    if (email.value === '') {
      message.style.display = "none";  // Si está vacío, ocultar el mensaje
      message.innerText = "";
    } else if (emailRegex.test(email.value)) {
      message.style.display = "none";  // Si es válido, ocultar el mensaje
      message.innerText = "";
    } else {
      message.style.display = "block";  // Si no es válido, mostrar el mensaje
      message.innerText = "El formato de correo no es válido";
      // No restablecer el valor del campo, solo mostrar el error
    }
  }
  
}

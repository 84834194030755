import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitbtn", "modulopersonalizado", "select", '']
  static values = {
    paciente: String
  }
  connect() {
    if (this.submitbtnTarget) {
      this.submitbtnTarget.hidden = true
    }
  }

  remotesubmit(e) {
    console.log('e', e.target)
    // console.log('this.paciente', this.pacienteValue)
    if (e.target.value  == 'create_custom_module') {
      this.modulopersonalizadoTarget.href = `/modulos_personalizados/new?modulo_selector=${e.target.dataset.modulo}&id_paciente=${this.pacienteValue}&selector_prestadores_proveedores=${e.target.dataset.prestaprovee}`
      this.selectTarget.selectedIndex = 0;
      this.modulopersonalizadoTarget.click()
    } else {
      if (e.target.value == '') {
      } else {
        this.submitbtnTarget.href = `${this.submitbtnTarget.href}&modulo=${e.target.options[e.target.selectedIndex].value}`
        this.submitbtnTarget.click()
      }
    }
  }

  remoteProfEspe(e) {
    // Seleccionar el elemento select de especialidades globalmente
    const idListaRecursos = e.target.dataset.idprofe;
    const listaRecursos = document.getElementById(idListaRecursos);

    if (e.target.value == '') {
      if (listaRecursos && listaRecursos.tomselect) {
        // Remove the selected option
        listaRecursos.tomselect.clear();
        // Resetear las opciones de TomSelect
        listaRecursos.tomselect.clearOptions(); // Elimina todas las opciones existentes
        // Actualizar el placeholder de TomSelect
        listaRecursos.tomselect.settings.placeholder = 'Seleccionar especialidad';
        listaRecursos.tomselect.input.placeholder = 'Seleccionar especialidad';
        listaRecursos.tomselect.refreshOptions(false); // Refresh the options to apply the new placeholder
      }
    } else {
      let error = e.target.parentElement.querySelector(".error-message");
      console.log('error', error)
      if (error && error.classList.contains("error-message")) {
        error.remove();
        e.target.classList.remove("is-invalid");

      }
      // Lógica existente cuando se selecciona una profesión
      let submitBtn = this.element.querySelector('#submitbtn')

      if (submitBtn.dataset.new) {
        this.url = '/modulos/new'
      } else if (submitBtn.dataset.edit) {
        this.url = `/modulos_edit/${submitBtn.dataset.idmodulo}/edit`
      } else {
        this.url = `/modulos_personalizados/new`
      }
      submitBtn.href = `${this.url}?profesion_id=${e.target.options[e.target.selectedIndex].value}&turbo_s=true&id_reemplazo=${e.target.dataset.idprofe}`
      submitBtn.click()
    }
  }

  clearErrors() {
    this.element.querySelectorAll(".error-message").forEach((error) => error.remove());
    this.element.querySelectorAll(".is-invalid").forEach((input) => input.classList.remove("is-invalid"));
  }
}

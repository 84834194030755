import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.disableFormInputs()
  }

  // FUNCTIONS FOR HABILITAR EDICION ---------------------------------------------

  disableFormInputs() {
    // Disable form inputs initially excluding elements with class 'notDisable'
    let inputs = document.querySelectorAll("form input:not([type=submit]):not([type=hidden]):not([type=button]):not(.notDisable)");
    inputs.forEach(input => {
      input.style.pointerEvents = "none";
    });

    // Remove the required from the selects
    let selects = document.querySelectorAll('select');
    selects.forEach(select => {
      // Check if the select contains the class notDisable, if so do not disable it, do not return just avoid
      if (!select.classList.contains('notDisable')) {
        select.style.pointerEvents = "none";
      }
    });

  }

  onEditBtnClick(event) {
    // Get the section where the edit btn was clicked
    let section = event.currentTarget.closest('.section');
    // Get the inputs within the section where the edit btn was clicked which are not submit buttons and dont have the class 'not_editable' also find checkboxes
    let inputs = section.querySelectorAll('input:not(.not_editable):not([type=submit]):not(.no-icon), select, input[type=checkbox]');
    inputs.forEach(input => {
      // Guarda el valor inicial como atributo de datos
      if (!input.dataset.originalValue) {
        input.dataset.originalValue = input.value || input.checked; // Guarda `checked` para checkboxes
      }
      input.style.pointerEvents = "auto";
      input.disabled = false;
      input.classList.add("edit-icon-input");
    });

    // Get the ID of the current section
    let sectionId = section.id;

    // Show the cancelar and submit buttons in the section and hide all the other ones
    let submitCancelDiv = section.querySelectorAll('.submit_cancel_buttons');
    if (submitCancelDiv.length > 1) {
      // Remove the d-none class from the submit and cancel buttons
      submitCancelDiv.forEach(div => {
        div.classList.remove('d-none');
      });
    } else {
      let submitCancelDiv = section.querySelector('.submit_cancel_buttons');
      if (submitCancelDiv) {
        submitCancelDiv.classList.remove('d-none');
      }
    }

    // Add the d-none class to the other submit cancel buttons not in the section
    let otherSubmitCancelDiv = document.querySelectorAll(`.submit_cancel_buttons:not(#${sectionId} .submit_cancel_buttons)`);
    if (otherSubmitCancelDiv.length === 0) {
      // Add the d-none class to the submit and cancel buttons
      otherSubmitCancelDiv.forEach(div => {
        div.classList.add('d-none');
      });
    }

    // Change the pointer events to none for all the inputs which are not in the section
    let otherInputs = document.querySelectorAll(`input:not(#${sectionId} input):not([type=submit]):not(.no-icon), select:not(#${sectionId} select), input[type=checkbox]:not(#${sectionId} input[type=checkbox])`);
    otherInputs.forEach(input => {
      input.style.pointerEvents = "none";
      input.disabled = true;
      input.classList.remove("edit-icon-input");
    });

    // Check if the section has the class seccion_contactos
    if (section.classList.contains('seccion_contactos')) {
      // Find the divs with the class col and add the class col-12
      let divs = section.querySelectorAll('.col');
      divs.forEach(div => {
        div.classList.remove('col');
        div.classList.add('col-11');
      });
    } else {
      // Find the contactos section
      let contactosSection = document.querySelector('.seccion_contactos');
      // Find the divs with the class col-11 and add the class col
      if (contactosSection) {
        let divs = contactosSection.querySelectorAll('.col-11');
        divs.forEach(div => {
          div.classList.remove('col-11');
          div.classList.add('col');
        });
      };
    }

    // Remove the submit cancel buttons from the other sections
    let otherSections = document.querySelectorAll(`.section:not(#${sectionId})`);
    otherSections.forEach(section => {
      let submitCancelDiv = section.querySelectorAll('.submit_cancel_buttons');
      // Add the d-none class to the submit and cancel buttons
      submitCancelDiv.forEach(div => {
        div.classList.add('d-none');
      });

      // Check if the section has the class seccion_contactos
      if (section.classList.contains('seccion_contactos')) {
        // Find the divs with the class col-11 and add the class col
        let divs = section.querySelectorAll('.col-11');
        divs.forEach(div => {
          div.classList.remove('col-11');
          div.classList.add('col');
        });
      }
    });

    // Check the url if it contains /tablasbasicas/proveedores, Update the cabecera section title
    if (window.location.href.includes('/tablasbasicas/proveedores')) {
      let cabeceraSection = document.querySelector('.titulo-cabecera');
      if (cabeceraSection.textContent === "Ver proveedor") {
        cabeceraSection.textContent = "Editar proveedor";
      } else {
        cabeceraSection.textContent = "Ver proveedor";
      }
    }
  }

  cancelarEdicion(event) {
    // Get the section where the cancel btn was clicked
    let section = event.currentTarget.closest('.section');

    if (section) {
      // Get the inputs within the section where the cancel btn was clicked which are not submit buttons and dont have the class 'not_editable'
      let inputs = section.querySelectorAll('input:not(.not_editable):not([type=submit]):not(.no-icon), select, input[type=checkbox]');
      inputs.forEach(input => {
        // Restaura el valor original desde el atributo de datos
        if (input.type === 'checkbox') {
          input.checked = input.dataset.originalValue === "true";
        } else {
          input.value = input.dataset.originalValue || "";
        }
        input.style.pointerEvents = "none";
        input.disabled = true;
        input.classList.remove("edit-icon-input");
      });

      // Hide the cancelar and submit buttons
      let submitCancelDiv = section.querySelectorAll('.submit_cancel_buttons');
      // Add the d-none class to the submit and cancel buttons
      submitCancelDiv.forEach(div => {
        div.classList.add('d-none');
      });

      // Check if the section has the class seccion_contactos
      if (section.classList.contains('seccion_contactos')) {
        // Find the divs with the class col-11 and add the class col
        let divs = section.querySelectorAll('.col-11');
        divs.forEach(div => {
          div.classList.remove('col-11');
          div.classList.add('col');
        });
      }
    } else {
      // Get all the inputs in the document which are not submit buttons and dont have the class not editable
      let inputs = document.querySelectorAll('input:not(.not_editable):not([type=submit]):not(.no-icon), select, input[type=checkbox]');
      inputs.forEach(input => {
        input.style.pointerEvents = "none";
        input.disabled = true;
        input.classList.remove("edit-icon-input");
      });

      // Hide the cancelar and submit buttons
      let submitCancelDiv = document.querySelectorAll('.submit_cancel_buttons');
      // Add the d-none class to the submit and cancel buttons
      submitCancelDiv.forEach(div => {
        div.classList.add('d-none');
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  connect() {
    // console.log(this.element)
    if (!this.element.tomselect) {
      this.initializeTomSelect();
    }
  }

  initializeTomSelect() {
    this.element.classList.remove("form-control")

    if (this.element.id && !this.element.tomselect) {
      const shouldDispatchChange = this.element.dataset.dispatchChange === 'true';

      const options = {
        plugins: {
          remove_button: {
            title: 'Remove this item',
          }
        },
        create: false,
        sortField: {
          field: "text",
          direction: "desc"
        },
        render: {
          option: function (data, escape) {
            const highlightedValue = 'create_custom_module';
            const className = data.value === highlightedValue ? 'text-primary fw-bold' : '';

            const recursos = data.recursos ? JSON.parse(data.recursos) : [];
            const insumos = data.insumos ? JSON.parse(data.insumos) : [];
            const equipamientos = data.equipamiento ? JSON.parse(data.equipamiento) : [];

            const recursosText = recursos.length > 0 ? recursos.map(recurso =>
              `${recurso[0]} - ${recurso[1]}hs - Cant: ${recurso[2]} - Por: ${recurso[3]}${recurso[4] ? ' - ' + recurso[4] : ''}`
            ).join('<br>') : 'No hay recursos';

            const insumosText = insumos.length > 0 ? insumos.map(insumo =>
              `${insumo[2]} - Cant: ${insumo[1]} - Por: ${insumo[0]}`
            ).join('<br>') : 'No hay insumos';

            const equipamientosText = equipamientos.length > 0 ? equipamientos.map(equip =>
              `${equip[0]}`
            ).join('<br>') : 'No hay equipamiento';

            return `<div class="option ${className}"
                        title="RECURSOS:\n${recursosText}\nINSUMOS:\n${insumosText}\nEQUIPAMIENTOS:\n${equipamientosText}"
                        data-value="${escape(data.value)}">
                        ${escape(data.text)}
                    </div>`;
          }
        }
      };

      // Solo si data-dispatch-change="true", agregamos la lógica para disparar 'change'
      if (shouldDispatchChange) {
        options.onItemRemove = (value) => {
          // Esto se dispara cuando se elimina un ítem (la profesión)
          console.log('onItemRemove', value);
          const event = new Event('change', { bubbles: true });
          this.element.dispatchEvent(event);
        }
      }

      new TomSelect(this.element, options);
    }
  }



  refresh() {
    if (this.element.tomselect) {
      this.element.tomselect.destroy();
    }
    this.initializeTomSelect();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "error"]

  connect() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
    this.minDate = new Date(this.today);
    this.minDate.setDate(this.today.getDate() - 180);
  }

  validateStartDate() {
    const startDateValue = this.startDateTarget.value;
    const endDateValue = this.endDateTarget.value;

    if (!startDateValue) return;

    const startDate = new Date(startDateValue);

    // Validar si es menor que la fecha mínima
    if (startDate < this.minDate) {
      const minDateFormatted = this.formatDate(this.minDate);
      this.errorTarget.textContent = `La fecha de inicio no puede ser anterior a ${minDateFormatted}.`;
      this.startDateTarget.value = "";
      return;
    }

    // Validar si es mayor que la fecha de finalización
    if (endDateValue) {
      const endDate = new Date(endDateValue);
      if (startDate > endDate) {
        this.errorTarget.textContent = "La fecha de inicio no puede ser posterior a la fecha de finalización.";
        this.startDateTarget.value = "";
        return;
      }
    }

    this.errorTarget.textContent = ""; // Limpiar mensajes de error
  }

  validateEndDate() {
    const startDateValue = this.startDateTarget.value;
    const endDateValue = this.endDateTarget.value;

    if (!endDateValue) return;

    const endDate = new Date(endDateValue);

    // Validar si es menor que hoy
    if (endDate < this.today) {
      this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a hoy.";
      this.endDateTarget.value = "";
      return;
    }

    // Validar si es menor que la fecha de inicio
    if (startDateValue) {
      const startDate = new Date(startDateValue);
      if (endDate < startDate) {
        this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a la fecha de inicio.";
        this.endDateTarget.value = "";
        return;
      }
    }

    this.errorTarget.textContent = ""; // Limpiar mensajes de error
  }

  validateYearLength(event) {
    const dateValue = event.target.value;
    const year = dateValue.split('-')[0];

    if (year.length !== 4) {
      alert("El año debe tener exactamente 4 dígitos.");
      event.target.value = "";
    }
  }

  // Método utilitario para formatear fechas
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  validateDateRange(event) {
    console.log("validateDateRange called"); // Log para verificar que se llama

    // Obtener la fecha ingresada
    const inputDate = new Date(event.target.value);
    
    // Obtener las fechas mínima y máxima
    const minDate = new Date(this.startDateTarget.min); // Obtener min del campo de inicio
    const maxDate = new Date(this.startDateTarget.max); // Obtener max del campo de inicio

    // Verificar que minDate no sea mayor que maxDate
    if (minDate > maxDate) {
        this.errorTarget.textContent = "Error: La fecha mínima no puede ser mayor que la fecha máxima.";
        event.target.value = ""; // Limpiar el valor
        return; // Salir de la función
    }

    // Formatear fechas a d-m-Y
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0 indexados
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Verificar si la fecha ingresada está fuera del rango
    if (inputDate < minDate || inputDate > maxDate) {
        // Sumar 1 día solo en el mensaje de error para minDate
        const minDateWithOffset = new Date(minDate);
        minDateWithOffset.setDate(minDateWithOffset.getDate() + 1);

        const maxDateWithOffset = new Date(maxDate);
        maxDateWithOffset.setDate(maxDateWithOffset.getDate() + 1);

        this.errorTarget.textContent = `No puede ingresar una fecha fuera del rango ${formatDate(minDateWithOffset)} a ${formatDate(maxDateWithOffset)}.`;
        event.target.value = ""; // Limpiar el valor si está fuera de rango
        return; // Salir de la función si la fecha está fuera de rango
    }

    // Validar que la fecha final no sea menor que la fecha de inicio
    const startDateValue = this.startDateTarget.value;
    if (startDateValue) {
        const startDate = new Date(startDateValue);
        if (inputDate < startDate) {
            this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a la fecha de inicio.";
            event.target.value = ""; // Limpiar el valor si es menor que la fecha de inicio
            return; // Salir de la función si la fecha final es inválida
        }
    }

    // Limpiar el mensaje de error si es válido
    this.errorTarget.textContent = "";
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["group", "input"];

  validate(event) {
    console.log('validate')
    let isValid = true;

    // Limpia los mensajes de error anteriores
    this.clearErrors();

    // Itera sobre los recursos para validar cada conjunto de inputs
    console.log('this.groupTargets',this.groupTargets)
    this.groupTargets.forEach((recurso) => {
      const inputs = recurso.querySelectorAll("input.obligatorio-recurso, select.obligatorio-recurso");
      // console.log('inputs',inputs)
      const filledInputs = Array.from(inputs).filter((input) => input?.value?.trim() !== "");

      if (filledInputs.length > 0) {
        // Si al menos un campo está completo, verifica que todos estén llenos
        inputs.forEach((input) => {
          if (input.value.trim() === "") {
            this.showError(input, "Este campo es obligatorio.");
            isValid = false;
          }
        });
      }
    });

    if (!isValid && event) {
      event.preventDefault();
    }
    return isValid;
  }

  // Muestra un mensaje de error debajo del campo
  showError(input, message) {
    const error = document.createElement("div");
    error.className = "error-message text-danger mt-1";
    error.innerText = message;

    // Agrega el mensaje de error después del input
    input.parentElement.appendChild(error);
    input.classList.add("is-invalid");
  }

  // Elimina todos los mensajes de error existentes, excepto los que tienen la clase 'dont_remove'
  clearErrors() {
    this.element.querySelectorAll(".error-message:not(.not_remove)").forEach((error) => error.remove());
    this.element.querySelectorAll(".is-invalid").forEach((input) => input.classList.remove("is-invalid"));
  }
}
